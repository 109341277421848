import React, { useState } from "react";
import { Box, Button, TextField } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import { createLead } from '../../services/customerService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CreateLead = () => {
  const [startDate, setStartDate] = useState(new Date());
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const handleFormSubmit = async (values) => {
    values.created_date = startDate.toISOString().slice(0, 10);
    let response = await createLead(values);
    if (response.status === 'success') {
      toast.success('Lead Added Successfully!');
      values.lead_mobile = "";
      values.lead_telephone = "";
      values.lead_name = "";
      values.lead_type = "";
      values.lead_type_name = "";
      values.lead_email = "";
      values.lead_en_type = "";
      values.lead_service_provider = "";
      values.lead_ship_type = "";
      values.lead_prospects = "";
      values.lead_weight = "";
      values.lead_revenue = "";
      values.lead_remarks = "";
      values.lead_entered_by = "";
    }
    if (response.status === 'error') {
      toast.error(response.error);
    }
  };
  const initialValues = {
    lead_mobile: "",
    lead_telephone: "",
    lead_name: "",
    lead_type: "",
    lead_type_name: "",
    lead_email: "",
    lead_en_type: "",
    lead_service_provider: "",
    lead_ship_type: "",
    lead_prospects: "",
    lead_weight: "",
    lead_revenue: "",//renamed to quote
    lead_remarks: "",
    lead_entered_by: "",
  };
  const checkoutSchema = yup.object().shape({
    lead_mobile: yup.string().matches(phoneRegExp, "phone number is not valid!").required("Required"),
    lead_name: yup.string().required("Required"),
    lead_email: yup.string().email("Invalid email!").required("Required"),
    lead_weight: yup.string().required("Required"),
    lead_revenue: yup.string().required("Required"), //renamed to quote
    lead_remarks: yup.string().required("Required"),
  });
  const getNameField = (custType) => {
    if (custType === 1) {
      return 'Name';
    } else if (custType === 2) {
      return 'Company Name';
    } else if (custType === 3) {
      return 'College Name';
    } else if (custType === 4) {
      return 'Citizenship';
    } else {
      return 'Name';
    }
  };

  return (
    <div>
      <Box m="20px">
        <Header title="ENQUIRY / CREATE LEAD" subtitle="Enquire (or) Create a new lead" />
        <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, handleReset }) => (
            <form onSubmit={handleSubmit}>
              <Box m="20px">
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.lead_type}
                      label="Customer Type"
                      name="lead_type"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Individual</MenuItem>
                      <MenuItem value={2}>SME</MenuItem>
                      <MenuItem value={3}>Student</MenuItem>
                      <MenuItem value={4}>Foreigner</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Mobile Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lead_mobile}
                    name="lead_mobile"
                    error={!!touched.lead_mobile && !!errors.lead_mobile}
                    helperText={touched.lead_mobile && errors.lead_mobile}
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Landline Number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lead_telephone}
                    name="lead_telephone"
                    error={!!touched.lead_telephone && !!errors.lead_telephone}
                    sx={{ gridColumn: "span 1" }}
                  />
                  
                  {(values.lead_type == '2' || values.lead_type == '3' || values.lead_type == '4') && (
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label={getNameField(values.lead_type)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.lead_type_name}
                      name="lead_type_name"
                      error={!!touched.lead_type_name && !!errors.lead_type_name}
                      sx={{ gridColumn: "span 1" }}
                    />
                  )}
                  
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Customer Name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lead_name}
                    name="lead_name"
                    error={!!touched.lead_name && !!errors.lead_name}
                    helperText={touched.lead_name && errors.lead_name}
                    sx={{ gridColumn: "span 2" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lead_email}
                    name="lead_email"
                    error={!!touched.lead_email && !!errors.lead_email}
                    helperText={touched.lead_email && errors.lead_email}
                    sx={{ gridColumn: "span 1" }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Enquiry Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.lead_en_type}
                      label="Enquiry Type"
                      name="lead_en_type"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Walk IN</MenuItem>
                      <MenuItem value={2}>Telephonic</MenuItem>
                      <MenuItem value={3}>Lead Generated By Store</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Service Provider</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.lead_service_provider}
                      label="Service Provider"
                      name="lead_service_provider"
                      onChange={handleChange}
                    >
                      <MenuItem value="DHL">DHL</MenuItem>
                      <MenuItem value="FedEx">FedEx</MenuItem>
                      <MenuItem value="Aramex">Aramex</MenuItem>
                      <MenuItem value="AEILS">AEILS</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Shipment Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.lead_ship_type}
                      label="Shipment Type"
                      name="lead_ship_type"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Export</MenuItem>
                      <MenuItem value={2}>Import / RPA</MenuItem>
                      <MenuItem value={3}>Domestic</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Customer Prospects</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.lead_prospects}
                      label="Customer Prospects"
                      name="lead_prospects"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>Hot Lead</MenuItem>
                      <MenuItem value={2}>Lost Customer</MenuItem>
                      <MenuItem value={3}>Looks High Potential hence CMR's visit required</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Weight"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lead_weight}
                    name="lead_weight"
                    error={!!touched.lead_weight && !!errors.lead_weight}
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Quote"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lead_revenue}
                    name="lead_revenue"
                    error={!!touched.lead_revenue && !!errors.lead_revenue}
                    sx={{ gridColumn: "span 1" }}
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Remarks"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lead_remarks}
                    name="lead_remarks"
                    error={!!touched.lead_remarks && !!errors.lead_remarks}
                    sx={{ gridColumn: "span 2" }}
                    multiline
                  />
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Lead Entered By"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.lead_entered_by}
                    name="lead_entered_by"
                    error={!!touched.lead_entered_by && !!errors.lead_entered_by}
                    sx={{ gridColumn: "span 2" }}
                    multiline
                  />
                  <FormControl fullWidth>
                    <label id="demo-simple-select-label">Next Follow up date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </FormControl>
                </Box>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Save
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default CreateLead;
