import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginCust } from "./services/customerService";
import { toast } from 'react-toastify';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();

  const loginAction = async (data) => {
    let response = await loginCust(data);
    if (response.status === 'success') {
      setUser(data.user_name);
      setToken(data.user_name);
      localStorage.setItem("site", data.user_name);
      toast.success('Login Successfull!');
      navigate("/");
      return;
    } else {
      toast.error(response.result[0].msg);
      console.log(response.result[0].msg);
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );

};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};