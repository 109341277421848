import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import './Search.css';
import Header from "../../components/Header";
import { searchCustomer } from "../../services/customerService";

import { Button, TextField } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const Search = () => {
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  let [custData, setCustData] = useState([]);
  let [selectedId, SetSelectedId] = useState('');
  const handleFormSubmit = async (values) => {
    (async () => {
      let response = await searchCustomer(values.searchQuery);
      setCustData(response.result);
    })();
  };
  const initialValues = {
    searchQuery: "",
  };
  const checkoutSchema = yup.object().shape({
    searchQuery: yup.string().required("Required"),
  })
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  useEffect(() => {
    (async () => {
      let response = await searchCustomer('9999');
      setCustData(response.result);
    })();
  }, []);

  const searchCustomerFun = (query) => {
    if (query !== '') {
      (async () => {
        let response = await searchCustomer(query);
        setCustData(response.result);
        if (response.result.length <= 0) {
          toast.error('No Records Found. Please change search criteria');
        }
      })();
    }
  };

  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "Id", width: 100 },
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 200,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      type: "number",
      headerAlign: "left",
      align: "left",
      width: 100,
    },
    { field: "telephone", headerName: "Telephone", width: 100 },
    {
      field: "type",
      headerName: "Type",
      width: 200,
      renderCell: (params) => {
        let typeVal = '';
        if (params.value === 1) {
          typeVal = 'Individual';
        } else if (params.value === 2) {
          typeVal = 'SME';
        } else if (params.value === 3) {
          typeVal = 'Student';
        } else if (params.value === 4) {
          typeVal = 'Foreigner';
        }
        return typeVal;
      }
    },
    { field: "address1", headerName: "Address", width: 250 },
    // { field: "city", headerName: "City", width: 100 },
    // { field: "zipCode", headerName: "Zip Code", width: 100 },
  ];
  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) => custData.find((row) => row.id === id));
    SetSelectedId(selectedRowsData[0].id);
  };
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Search Customer" subtitle="" />
      </Box>
      <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, handleReset }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Mobile Number"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.searchQuery}
                name="searchQuery"
                // error={!!touched.mobile && !!errors.mobile}
                helperText={touched.searchQuery && errors.searchQuery}
                sx={{ gridColumn: "span 1" }}
              />
            </Box>
            <div className="search-btns m-1">
              <div>
                <Button title="Search" className="m-1" type="submit" color="secondary" variant="contained" onClick={() => searchCustomerFun(values.searchQuery)}>
                  Search
                </Button>
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  color="secondary"
                  variant="contained"
                  className="m-1"
                  title="Book International Shipment"
                >
                  BIS
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleClose}>DHL</MenuItem>
                  <MenuItem onClick={() => {
                    navigate(`/booking/${selectedId}`);
                    handleClose();
                  }}>FedEx</MenuItem>
                  <MenuItem onClick={() => {
                    navigate(`/booking/${selectedId}`);
                    handleClose();
                  }}>Aramex</MenuItem>
                  <MenuItem onClick={() => {
                    navigate(`/booking/${selectedId}`);
                    handleClose();
                  }}>AEILS</MenuItem>
                </Menu>
              </div>
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open2 ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open2 ? 'true' : undefined}
                  onClick={handleClick2}
                  color="secondary"
                  variant="contained"
                  className="m-1"
                >
                  Book Dom Shipment
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl2}
                  open={open2}
                  onClose={handleClose2}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleClose2}>a</MenuItem>
                  <MenuItem onClick={handleClose2}>b</MenuItem>
                  <MenuItem onClick={handleClose2}>c</MenuItem>
                </Menu>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        {/* {custData && custData.length > 0 && (
          
        )} */}
        <DataGrid
          // checkboxSelection
          rows={custData}
          columns={columns}
        // components={{ Toolbar: GridToolbar }}
          localeText={{ noRowsLabel: "No Records Found" }}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          // disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default Search;
