import axios from 'axios';
import React from 'react';

export async function createCustomer(req) {
  let resp = {};
  await axios.post('https://72v.749.mytemp.website/addcustomer', { ...req }).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}
export async function loginCust(req) {
  let resp = {};
  await axios.post('https://72v.749.mytemp.website/login', { ...req }).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}
export async function createLead(req) {
  let resp = {};
  await axios.post('https://72v.749.mytemp.website/addlead', { ...req }).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}
export async function createExLead(req) {
  let resp = {};
  await axios.post('https://72v.749.mytemp.website/addexlead', { ...req }).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}
export async function createBooking(req) {
  let resp = {};
  await axios.post('https://72v.749.mytemp.website/addbooking', { ...req }).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}
export async function searchCustomer(req) {
  let resp = {};
  await axios.get(`https://72v.749.mytemp.website/searchCustomer/${req}`).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}
export async function getLeadRemarksList(req) {
  let resp = {};
  await axios.get(`https://72v.749.mytemp.website/getLeadRemarks/${req}`).then(
    (response) => {
      resp = response.data;
      console.log(resp);
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}

export async function getCustomerDetails(req) {
  let resp = {};
  await axios.get(`https://72v.749.mytemp.website/getCustomer/${req}`).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}

export async function getHotLeads() {
  let resp = {};
  await axios.get(`https://72v.749.mytemp.website/getHotleads`).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}
export async function getExternalLeads() {
  let resp = {};
  await axios.get(`https://72v.749.mytemp.website/getExternalLeads`).then(
    (response) => {
      resp = response.data;
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}

export async function getGoogleLocationDetails(code) {
  let resp = {};
  await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${code}&key=AIzaSyAsMCiqPGUMUIeNzXJ77uyHLrV74cscV3s`).then(
    (response) => {
      if(response.data.results.length > 0) {
        let address = {};
        const address_components = response.data.results[0].address_components;
        address_components.forEach(element => {
            address[element.types[0]] = element.long_name;
        });
        resp = address;
      }
    },
    (error) => {
      resp = error.response.data;
    },
  );
  return resp;
}