import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button } from '@mui/material';

import './Booking.css';

const Performa = ({ performa, setPerforma, noOfPcs }) => {
  const [opList, setOpList] = useState([]);
  const handleFormSubmit = async (values) => {
    setPerforma([
      ...performa,
      { ...initialValues },
    ]);
  };
  const handleChange = (e, i) => {
    var tempPiece = performa;
    tempPiece[i] = {
      ...tempPiece[i],
      [e.target.name]: e.target.value
    };
    setPerforma([...tempPiece]);
    e.preventDefault();
  };
  const initialValues = {
    prfm_desc: "",
    prfm_qty: "",
    prfm_hsn: "",
    prfm_unit: "",
    prfm_total: "",
    prfm_piece: "",
    prfm_net_weight: "",
    prfm_gross_weight: "",
  };

  useEffect(() => {
    let optionArray = [];
    for(let i = 1; i <= parseInt(noOfPcs); i++) {
      optionArray.push('Pieces'+i);
    }
    setOpList(optionArray);
  }, [noOfPcs]);

  useEffect(() => {
    performa.forEach((element, i) => {
      performa[i].prfm_total = parseFloat(performa[i].prfm_unit) * parseFloat(performa[i].prfm_qty);
    });
  }, [performa]);

  const getPerformaContent = useCallback(() => {
    return (
      <>
      {performa && performa.map((item, i) => {
        return (
          <>
            <div className="row d-flex flex-row mt-2">
              <div className="col-md-1">
                <span>{i+1}</span>
              </div>
              <div className="col-md-2">
                <textarea
                  type="text"
                  onChange={(e) => handleChange(e, i)}
                  value={item.prfm_desc}
                  name="prfm_desc"
                  className="piece-space w-100"
                />
              </div>
              <div className="col-md-1">
                <input
                  type="text"
                  onChange={(e) => handleChange(e, i)}
                  value={item.prfm_qty}
                  name="prfm_qty"
                  className="piece-space w-100"
                />
              </div>
              <div className="col-md-2">
                <input
                  type="text"
                  onChange={(e) => handleChange(e, i)}
                  value={item.prfm_hsn}
                  name="prfm_hsn"
                  className="piece-space w-100"
                />
              </div>
              <div className="col-md-1">
                <input
                  type="text"
                  onChange={(e) => handleChange(e, i)}
                  value={item.prfm_unit}
                  name="prfm_unit"
                  className="piece-space w-100"
                />
              </div>
              <div className="col-md-1">
                <input
                  type="text"
                  onChange={(e) => handleChange(e, i)}
                  value={item.prfm_total}
                  name="prfm_total"
                  className="piece-space w-100"
                />
              </div>
              <div className="col-md-1">
                <select className="piece-space w-100" name="prfm_piece" id="prfm_piece1" onChange={(e) => handleChange(e, i)}>
                  {opList.map((ele) => {
                    return <option value={ele}>{ele}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-1">
                <input
                  type="text"
                  onChange={(e) => handleChange(e, i)}
                  value={item.prfm_net_weight}
                  name="prfm_net_weight"
                  sx={{ gridColumn: "span 2" }}
                  className="piece-space w-100"
                />
              </div>
              <div className="col-md-2">
                <input
                  type="text"
                  onChange={(e) => handleChange(e, i)}
                  value={item.prfm_gross_weight}
                  name="prfm_gross_weight"
                  sx={{ gridColumn: "span 2" }}
                  className="piece-space w-100"
                />
              </div>
            </div>
            <hr className="mt-2" />
          </>
        );
      })}
      </>
    );
  }, [performa]);

  return (
    <Box>
      <Box display="flex" justifyContent="end" mt="20px">
        {noOfPcs !== '1' && parseInt(noOfPcs)-1 >= performa.length && (
          <Button type="submit" color="secondary" variant="contained" onClick={handleFormSubmit}>
            Add
          </Button>
        )}
      </Box>
      <div className="row d-flex flex-row mt-2 piece-header-main">
        <div className="col-md-1 m-0 piece-space piece-header">
          <span>SL No</span>
        </div>
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Desc of Goods</span>
        </div>
        <div className="col-md-1 m-0 piece-space piece-header">
          <span>Qty</span>
        </div>
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Export HSN Code</span>
        </div>
        <div className="col-md-1 m-0 piece-space piece-header">
          <span>Unit Value</span>
        </div>
        <div className="col-md-1 m-0 piece-space piece-header">
          <span>Sub Total</span>
        </div>
        <div className="col-md-1 m-0 piece-space piece-header">
          <span>Select Piece</span>
        </div>
        <div className="col-md-1 m-0 piece-space piece-header">
          <span>Net Weight</span>
        </div>
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Gross Weight</span>
        </div>
      </div>
      {getPerformaContent()}
    </Box>
  );
}

export default Performa;
