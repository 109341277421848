import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { MyProSidebarProvider } from "./pages/global/sidebar/sidebarContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";

import Topbar from "./pages/global/Topbar";

import Dashboard from "./pages/dashboard";
// import Team from "./pages/team";
// import Invoices from "./pages/invoices";
// import Contacts from "./pages/contacts";
// import Form from "./pages/form";
// import Calendar from "./pages/calendar";
// import Bar from "./pages/bar";
// import Line from "./pages/line";
// import Pie from "./pages/pie";
// import FAQ from "./pages/faq";
// import Geography from "./pages/geography";
import AddCustomer from "./pages/customer/index";
import Search from "./pages/search/index";
import Login from "./pages/login/index";
import Booking from "./pages/booking/index";
import CreateLead from "./pages/createlead/index";
import ExLead from "./pages/createlead/external";
import Hotlead from "./pages/createlead/hotlead";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './App.css';
import AuthProvider from "./AuthProvider";
import PrivateRoute from "./PrivateRoute";
const App = () => {
  const [theme, colorMode] = useMode();
  const [isActive, setIsActive] = useState(false);
  // useEffect(() => {
  //   alert();
  // }, [isActive]);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <MyProSidebarProvider>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              limit={1}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
              theme="colored"
              style={{width: '80%'}}
            />
            <div style={{ height: "100%", width: "100%" }}>
              <main>
                <Topbar />
                <Routes>
                  <Route path="/login" element={<Login isActive={isActive} setIsActive={setIsActive} />} />
                  <Route element={<PrivateRoute />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/addcustomer" element={<AddCustomer />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/booking/:id" element={<Booking />} />
                    <Route path="/createlead" element={<CreateLead />} />
                    <Route path="/exlead" element={<ExLead />} />
                    <Route path="/hotlead" element={<Hotlead />} />
                  </Route>
                </Routes>
              </main>
            </div>
          </MyProSidebarProvider>
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
