import React, { useEffect, useState } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import { createExLead, getLeadRemarksList } from '../../services/customerService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const History = (params) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [checked, setChecked] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [remarkList, setRemarkList] = React.useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const handleFormSubmit = async (values) => {
    values.follow_up_date = startDate.toISOString().slice(0, 10);
    values.is_hotlead = params.fromHotLead ? true : checked;
    values.booking_date = params.leadHistory.booking_date;
    values.lead_id = params.leadHistory.lead_id;
    values.is_active = isActive;
    let response = await createExLead(values);
    if (response.status === 'success') {
      toast.success('Lead Updated Successfully!');
      params.closePiecesDetail();
      values.reason = '';
      values.remarks = '';
    }
    if (response.status === 'error') {
      toast.error('Lead cannot be updated');
    }
  };
  const initialValues = {
    remarks: "",
    reason: "",
  };
  const checkoutSchema = yup.object().shape({
    // lead_mobile: yup.string().matches(phoneRegExp, "phone number is not valid!").required("Required"),
    // lead_name: yup.string().required("Required"),
    // lead_email: yup.string().email("Invalid email!").required("Required"),
    // lead_weight: yup.string().required("Required"),
    // lead_revenue: yup.string().required("Required"),
    // lead_remarks: yup.string().required("Required"),
  })
  const formatDate = (date) => {
    if (!date) {
      return '';
    }
    return moment(date).tz('UTC').format('YYYY-MM-DD');
  };

  useEffect(() => {
    console.log('params');
    console.log(params);
    if (params.leadHistory && params.leadHistory.lead_id) {
      (async () => {
        let response = await getLeadRemarksList(params.leadHistory.lead_id);
        console.log(response.result);
        setRemarkList(response.result);
      })();
    }
  }, [params]);
  return (
    <Box>
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, handleReset }) => (
          <form onSubmit={handleSubmit}>
            <Box m="20px">
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label=""
                  onBlur={handleBlur}
                  value={formatDate(params.leadHistory.created_date)}
                  name="created_date"
                  sx={{ gridColumn: "span 1" }}
                  disabled={true}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Customer Name"
                  value={params.leadHistory.lead_name}
                  name="lead_name"
                  sx={{ gridColumn: "span 2" }}
                  disabled={true}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Mobile Number"
                  value={params.leadHistory.lead_mobile}
                  name="lead_mobile"
                  sx={{ gridColumn: "span 1" }}
                  disabled={true}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Landline Number"
                  value={params.leadHistory.lead_telephone}
                  name="lead_telephone"
                  sx={{ gridColumn: "span 1" }}
                  disabled={true}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Last Booking date"
                  onBlur={handleBlur}
                  value={formatDate(params.leadHistory.created_date)}
                  name="created_date"
                  sx={{ gridColumn: "span 1" }}
                  disabled={true}
                />
              </Box>
              {/* {remarkList.length > 0 && ( */}
                {remarkList.length > 0 && remarkList.map((ele, i) => {
                  return (
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                      <span>{i+1}{". " + ele.remarks}</span>
                    </Box>
                  );
                })}
              {/* )} */}
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Remarks"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.remarks}
                  name="remarks"
                  error={!!touched.remarks && !!errors.remarks}
                  sx={{ gridColumn: "span 4" }}
                  multiline
                  className='mt-5'
                />
                <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={values.reason}
                    label="Reason"
                    name="reason"
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Customer Interested</MenuItem>
                    <MenuItem value={2}>Wrong Number/Non Contactable/Outstation Number</MenuItem>
                    <MenuItem value={3}>Busy/Ringing/Callback Later</MenuItem>
                    <MenuItem value={4}>Converted On Other Mobile Number</MenuItem>
                    <MenuItem value={5}>Shipment Already Sent</MenuItem>
                    <MenuItem value={6}>Shipment Lost Due to Non Serviceable Area</MenuItem>
                    <MenuItem value={7}>Shipment Lost Due to High Rate</MenuItem>
                    <MenuItem value={8}>Shipment Lost Due to Contraband Item</MenuItem>
                    <MenuItem value={9}>Delivery Enquiry</MenuItem>
                    <MenuItem value={10}>No Potential</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                  <label id="demo-simple-select-label">Next Follow up date</label>
                  <DatePicker fullWidth selected={startDate} onChange={(date) => setStartDate(date)} />
                </FormControl>
              </Box>
              {params.leadHistory.is_hotlead === 0 && (
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                  sx={{
                    "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                  }}
                >
                  <FormControlLabel
                    label="Hot Lead"
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        label="Hot Lead"
                      />
                    }
                  />
                </Box>
              )}
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="button" color="secondary" variant="contained" className='m-3' onClick={() => {
                  params.closePiecesDetail();
                  handleReset();
                }}>
                Close Lead
              </Button>
              <Button type="submit" color="secondary" variant="contained" className='m-3'>
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default History;
