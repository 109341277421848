import React, { useState } from 'react'
import { Box, Button } from '@mui/material';

import './Booking.css';

const Pieces = ({pieces, setPieces, noOfPcs}) => {
  const handleFormSubmit = async (values) => {
    setPieces([
      ...pieces,
      { ...initialValues },
    ]);
  };
  const handleChange = (e, i) => {
    var tempPiece = pieces;
    tempPiece[i] = {
      ...tempPiece[i],
      [e.target.name]: e.target.value
    };
    setPieces([ ...tempPiece ]);
    e.preventDefault();
  };
  const initialValues = {
    piece_gross_weight: "",
    piece_length: "",
    piece_width: "",
    piece_height: "",
    piece_vol_weight: "",
    piece_contents: "",
  };

  return (
    <Box>
      <Box display="flex" justifyContent="end" mt="20px">
        {noOfPcs !== '1' && parseInt(noOfPcs)-1 >= pieces.length && (
          <Button type="submit" color="secondary" variant="contained" onClick={handleFormSubmit}>
            Add
          </Button>
        )}
      </Box>
      <div md={12} className="d-lg-flex flex-row mt-2 col-md-a piece-header-main">
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Gross Weight</span>
        </div>
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Length</span>
        </div>
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Width</span>
        </div>
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Height</span>
        </div>
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Vol.weight</span>
        </div>
        <div className="col-md-2 m-0 piece-space piece-header">
          <span>Contents</span>
        </div>
      </div>
      {pieces && pieces.map((item, i) => {
        return (
          <>
            <div md={12} className="d-lg-flex flex-row mt-2">
              <input
                type="text"
                onChange={(e) => handleChange(e, i)}
                value={item.piece_gross_weight}
                name="piece_gross_weight"
                sx={{ gridColumn: "span 1" }}
                className="piece-space"
                placeholder="Gross Weight"
              />
              <input
                type="text"
                onChange={(e) => handleChange(e, i)}
                value={item.piece_length}
                name="piece_length"
                sx={{ gridColumn: "span 1" }}
                className="piece-space"
                placeholder="Length"
              />
              <input
                type="text"
                onChange={(e) => handleChange(e, i)}
                value={item.piece_width}
                name="piece_width"
                sx={{ gridColumn: "span 1" }}
                className="piece-space"
                placeholder="Width"
              />
              <input
                type="text"
                onChange={(e) => handleChange(e, i)}
                value={item.piece_height}
                name="piece_height"
                sx={{ gridColumn: "span 1" }}
                className="piece-space"
                placeholder="Height"
              />
              <input
                type="text"
                onChange={(e) => handleChange(e, i)}
                value={item.piece_vol_weight}
                name="piece_vol_weight"
                sx={{ gridColumn: "span 1" }}
                className="piece-space"
                placeholder="Vol Weight"
              />
              <input
                type="text"
                onChange={(e) => handleChange(e, i)}
                value={item.piece_contents}
                name="piece_contents"
                sx={{ gridColumn: "span 2" }}
                className="piece-space"
                placeholder="Contents"
              />
            </div>
            <hr/>
          </>
        );
      })}
    </Box>
  );
}

export default Pieces;
