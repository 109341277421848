import React, { useEffect } from 'react'
import { Box, Button, TextField } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import {createCustomer, getGoogleLocationDetails} from "../../services/customerService";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';

const AddCustomer = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const handleFormSubmit = async (values) => {
    values.dob = values.dob.toISOString().slice(0, 10);
    values.doc_expiry_date = values.doc_expiry_date.toISOString().slice(0, 10);
    let response = await createCustomer(values);
    if (response.status === 'success') {
      toast.success('Customer Added Successfully!');
      values.name= '';
      values.email= '';
      values.type= '';
      values.type_name= '';
      values.mobile= '';
      values.address1= '';
      values.address2= '';
      values.address3= '';
      values.age= '';
      values.pincode= '';
      values.country= '';
      values.state= '';
      values.city= '';
      values.telephone= '';
      values.dob= '';
      values.doc_type= '';
      values.doc_number= '';
      values.doc_expiry_date= '';
    }
    if (response.status === 'error') {
      toast.error(response.error);
    }
  };

  const onSelectCustomerHandler = async (setFieldValue, pin) => {
    setFieldValue('city', '');
    setFieldValue('state', '');
    setFieldValue('country', '');
    if(pin.length >= 5) {
      let response = await getGoogleLocationDetails(pin);
      if(response) {
        setFieldValue('city', response.administrative_area_level_3 ? response.administrative_area_level_3 : response.locality || response.administrative_area_level_2);
        setFieldValue('state', response.administrative_area_level_1);
        setFieldValue('country', response.country);
      }
    }
  };
  const initialValues = {
    name: "",
    email: "",
    type: "",
    type_name: "",
    mobile: "",
    address1: "",
    address2: "",
    address3: "",
    age: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    telephone: "",
    dob: "",
    doc_type: "",
    doc_number: "",
    doc_expiry_date: "",
  };
  
  // useEffect(() => {
  //   setFieldValue("pincode", '22222');
  // }, []);

  const checkoutSchema = yup.object().shape({
    name: yup.string().required("Required"),
    email: yup.string().email("Invalid email!").required("Required"),
    mobile: yup.string().matches(phoneRegExp, "phone number is not valid!").required("Required"),
    address1: yup.string().required("Required"),
    address2: yup.string().required("Required"),
    // age: yup.string().required("Required"),
  })
  const getNameField = (custType) => {
    if (custType === 1) {
      return 'Name';
    } else if (custType === 2) {
      return 'Company Name';
    } else if (custType === 3) {
      return 'College Name';
    } else if (custType === 4) {
      return 'Citizenship';
    } else {
      return 'Name';
    }
  };

  return (
    <div>
      <Box m="20px">
        <Header title="CREATE CUSTOMER" subtitle="Create a New Customer Profile" />
        <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit, handleReset, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>CREATE CUSTOMER</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box m="20px">
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Customer Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.type}
                          label="Customer Type"
                          name="type"
                          onChange={handleChange}
                        >
                          <MenuItem value={1}>Individual</MenuItem>
                          <MenuItem value={2}>SME</MenuItem>
                          <MenuItem value={3}>Student</MenuItem>
                          <MenuItem value={4}>Foreigner</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Mobile Number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.mobile}
                        name="mobile"
                        error={!!touched.mobile && !!errors.mobile}
                        helperText={touched.mobile && errors.mobile}
                        sx={{ gridColumn: "span 1" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        name="name"
                        error={!!touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                        sx={{ gridColumn: "span 2" }}
                      />
                      {(values.type == '2' || values.type == '3' || values.type == '4') && (
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label={getNameField(values.type)}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.type_name}
                          name="type_name"
                          error={!!touched.type_name && !!errors.type_name}
                          sx={{ gridColumn: "span 1" }}
                        />
                      )}
                      <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                        <label id="demo-simple-select-label">DOB</label>
                        <DatePicker
                          fullWidth
                          selected={values.dob}
                          onChange={(date) => setFieldValue("dob", date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode= "select"
                        />
                      </FormControl>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        error={!!touched.email && !!errors.email}
                        helperText={touched.email && errors.email}
                        sx={{ gridColumn: "span 1" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Telephone Number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.telephone}
                        name="telephone"
                        error={!!touched.telephone && !!errors.telephone}
                        sx={{ gridColumn: "span 1" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Address 1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address1}
                        name="address1"
                        error={!!touched.address1 && !!errors.address1}
                        helperText={touched.address1 && errors.address1}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Address 2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address2}
                        name="address2"
                        error={!!touched.address2 && !!errors.address2}
                        helperText={touched.address2 && errors.address2}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Address 3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.address3}
                        name="address3"
                        error={!!touched.address3 && !!errors.address3}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Pincode"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          setFieldValue("pincode", e.target.value);
                          // e.target.value.length >=5 && alert('yes');
                          onSelectCustomerHandler(setFieldValue, e.target.value);
                        }}
                        value={values.pincode}
                        name="pincode"
                        error={!!touched.pincode && !!errors.pincode}
                        sx={{ gridColumn: "span 2" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Country"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.country}
                        name="country"
                        error={!!touched.country && !!errors.country}
                        sx={{ gridColumn: "span 1" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="State"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.state}
                        name="state"
                        error={!!touched.state && !!errors.state}
                        sx={{ gridColumn: "span 1" }}
                      />
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="City"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.city}
                        name="city"
                        error={!!touched.city && !!errors.city}
                        sx={{ gridColumn: "span 1" }}
                      />
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography>ID DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box m="20px">
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Document Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.doc_type}
                          label="Document Type"
                          name="doc_type"
                          onChange={handleChange}
                        >
                          <MenuItem value={1}>PAN Card</MenuItem>
                          <MenuItem value={2}>Driving License</MenuItem>
                          <MenuItem value={3}>Voter ID</MenuItem>
                          <MenuItem value={4}>Aadhar Card</MenuItem>
                          <MenuItem value={5}>Passport</MenuItem>
                          <MenuItem value={6}>GST</MenuItem>
                          <MenuItem value={7}>KYC not collected</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Document Number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.doc_number}
                        name="doc_number"
                        sx={{ gridColumn: "span 2" }}
                      />
                      <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                        <label id="demo-simple-select-label">Document Expiry Date</label>
                        <DatePicker
                          fullWidth
                          selected={values.doc_expiry_date}
                          onChange={(date) => setFieldValue("doc_expiry_date", date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode= "select"
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button type="submit" color="secondary" variant="contained">
                  Create New User
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </div>
  );
}

export default AddCustomer;
