import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import './Search.css';
import Header from "../../components/Header";
import { getExternalLeads } from "../../services/customerService";

import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import History from './history';
import moment from 'moment-timezone';


const External = () => {
  const noneDisplay = {
    display: 'none',
  };
  const blockDisplay = {
    display: 'block',
    backgroundColor: 'rgba(0,0,0,3)',
  };
  const theme = useTheme();
  let [custData, setCustData] = useState([]);
  const [showModalStyle, setShowModalStyle] = useState(noneDisplay);
  const [leadHistory, setLeadHistory] = useState(noneDisplay);
  const openPiecesDetails = () => {
    setShowModalStyle(blockDisplay);
  };
  const closePiecesDetail = () => {
    setShowModalStyle(noneDisplay);
  };
  const initialValues = {
    searchQuery: "",
  };
  const checkoutSchema = yup.object().shape({
    searchQuery: yup.string().required("Required"),
  })
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  useEffect(() => {
    (async () => {
      let response = await getExternalLeads();
      setCustData(response.result);
      console.log(response.result);
    })();
  }, []);

  const getLeadHistory = (data) => {
    // console.log(data);
    setLeadHistory(data);
    openPiecesDetails();
  };
  const formatDate = (date) => {
    if (!date) {
      return '';
    }
    return moment(date).tz('UTC').format('YYYY-MM-DD');
  };

  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "booking_date",
      headerName: "Date of Lead",
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "lead_name",
      headerName: "Customer Name",
      renderCell: (params) => {
        return (
          <div>
            <button type="button" className="btn btn-secondary btn-sm w-100" onClick={() => getLeadHistory(params.row)}>
              {params.value}
            </button>
          </div>
        );
      }
    },
    { field: "lead_mobile", headerName: "Mobile" },
    { field: "lead_telephone", headerName: "Landline" },
    // { field: "lead_id", headerName: "Lead Source" },
    // { field: "booking_date", headerName: "Lead Booking Date" },
    {
      field: "follow_up_date",
      headerName: "Follow up Date",
      valueFormatter: (params) => formatDate(params.value),
    },
    // { field: "booking_date", headerName: "Customer Type" },
    // { field: "booking_date", headerName: "Pending since days" },
    // { field: "booking_date", headerName: "Follow ups done" },
    { field: "remarks", headerName: "Remarks" },
    // {
    //   field: "name",
    //   headerName: "Name",
    //   cellClassName: "name-column--cell",
    //   width: 200,
    // },
    // {
    //   field: "mobile",
    //   headerName: "Mobile",
    //   type: "number",
    //   headerAlign: "left",
    //   align: "left",
    //   width: 100,
    // },
    // { field: "telephone", headerName: "Telephone", width: 100 },
    // { field: "type", headerName: "Type", width: 200 },
    // { field: "address1", headerName: "Address", width: 250 },
    // { field: "city", headerName: "City", width: 100 },
    // { field: "zipCode", headerName: "Zip Code", width: 100 },
  ];
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="External Leads" subtitle="" />
      </Box>
      <div className="modal modal-lg" tabindex="-1" role="dialog" style={showModalStyle}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <Header title="Lead History" subtitle="" />
              </h5>
            </div>
            <div className="modal-body">
              <History leadHistory={leadHistory} closePiecesDetail={closePiecesDetail}/>
            </div>
          </div>
        </div>
      </div>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={custData}
          columns={columns}
          localeText={{ noRowsLabel: "No Records Found" }}
        />
      </Box>
    </Box>
  );
};

export default External;
