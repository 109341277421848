import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Header from "../../components/Header";

import { Button, TextField } from '@mui/material'
import { Formik } from "formik";
import * as yup from 'yup';
import { useMediaQuery } from "@mui/material";
import { loginCust } from "../../services/customerService";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../AuthProvider";

const Login = (isActive, setIsActive) => {
  const auth = useAuth();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const navigate = useNavigate();
  const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
  const handleFormSubmit = async (values) => {
    auth.loginAction(values);
    // let response = await loginCust(values);
    // console.log(response);
    // if (response.status === 'success') {
    //   auth.loginAction(values);
    // } else {
    //   console.log(response.result[0].msg);
    // }
  };
  const initialValues = {
    user_name: "",
    password: "",
  };
  const checkoutSchema = yup.object().shape({
    user_name:yup.string().required("Required"),
    password:yup.string().required("Required"),
  })

  return (
    <Box m="20px">
      <div>
        <div className="justify-content-center row">
          <div className="col-4">
            <Header title="LOGIN TO AIREAZY" subtitle="" />
          </div>
        </div>
        <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema}>
          {({ values, errors, touched, handleBlur, handleChange, handleSubmit,}) => (
            <div className="justify-content-center row">
              <div className="col-4">
                <form onSubmit={handleSubmit}>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="User Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.user_name}
                      name="user_name"
                      error={!!touched.user_name && !!errors.user_name}
                      helperText={touched.user_name && errors.user_name}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                    mt="20px"
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      name="password"
                      error={!!touched.password && !!errors.password}
                      helperText={touched.password && errors.password}
                      sx={{ gridColumn: "span 4" }}
                    />
                  </Box>
                  <Box display="flex" justifyContent="end" mt="20px">
                    <Button type="submit" color="secondary" variant="contained">
                      Login
                    </Button>
                  </Box>
                </form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Box>
  );
};

export default Login;
