import * as yup from 'yup';
const phoneRegExp = /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const INITIAL_FORM_STATE = {
    //shipper fields
    company_name: "",
    contact_person: "",
    email: "",
    mobile: "",
    address1: "",
    address2: "",
    company_name2: "",
    gstin: "",
    pincode: "",
    country: "",
    state: "",
    city: "",
    telephone: "",
    status: "",
    //consignee fields
    con_company_name: "",
    con_contact_person: "",
    con_email: "",
    con_mobile: "",
    con_address1: "",
    con_address2: "",
    con_pincode: "",
    con_country: "",
    con_city: "",
    con_telephone: "",
    //shipment info fields
    ship_ship_date: "",
    ship_product_type: "",
    ship_pieces: "",
    ship_gross_weight: "",
    ship_length: "",
    ship_width: "",
    ship_height: "",
    ship_volumentric_weight: "0",
    ship_sample_or_gift: "",//it is a radio button, now we can have a dropdown
    ship_contents: "",
    ship_doc_type: "",
    ship_har_comm_code: "",
    ship_doc_no: "",
    ship_remarks: "",
    ship_expiry_date: "",
    ship_insurance: "",
    ship_invoice_no: "",
    ship_invoice_date: "",
    //charges fields
    charge_is_hit_scl: "",
    charge_basic_charge: "",
    charge_fuel_charge: "",
    charge_insurance_premium: "",
    charge_ras: "",
    charge_elevated_risk: "",
    charge_net_amount: "",
    charge_gst_tax: "",
    charge_tds: "",
    charge_final_payment: "",
    //payment fileds
    payment_shipping_acc_no: "",
    payment_mode_1: "",
    payment_mode_2: "",
    payment_net_amt_1: "",
    payment_net_amt_2: "",
    payment_gst_tax_1: "",
    payment_gst_tax_2: "",
    payment_amount_1: "",
    payment_amount_2: "",
    payment_final_payment: "",
    payment_card_no: "",
    payment_appr_code: "",
    payment_bank: "",
    payment_bank_branch: "",
    payment_utr_no: "",
    payment_transaction_no: "",
  };

  export const checkoutSchema = yup.object().shape({
    //shipper
    company_name: yup.string().required("Required"),
    contact_person: yup.string().required("Required"),
    email: yup.string().email("Invalid email!").required("Required"),
    mobile: yup.string().matches(phoneRegExp, "phone number is not valid!").required("Required"),
    address1: yup.string().required("Required"),
    address2: yup.string().required("Required"),
    gstin: yup.string().required("Required"),
    pincode: yup.string().required("Required"),
    country: yup.string().required("Required"),
    state: yup.string().required("Required"),
    city: yup.string().required("Required"),
    // telephone: yup.string().required("Required"),
    //consignee
    con_contact_person: yup.string().required("Required"),
    con_mobile: yup.string().required("Required"),
    con_address1: yup.string().required("Required"),
    con_address2: yup.string().required("Required"),
    con_pincode: yup.string().required("Required"),
    con_country: yup.string().required("Required"),
    con_city: yup.string().required("Required"),
    //shipment
    ship_ship_date: yup.string().required("Required"),
    ship_product_type: yup.string().required("Required"),
    ship_pieces: yup.string().required("Required"),
    ship_gross_weight: yup.string().required("Required"),
    ship_sample_or_gift: yup.string().required("Required"),//it is a radio button, now we can have a dropdown
    ship_contents: yup.string().required("Required"),
    //charge
    charge_basic_charge: yup.string().required("Required"),
    //payment
    payment_net_amt_1: yup.string().required("Required"),
    payment_net_amt_2: yup.string().required("Required"),
    payment_amount_1: yup.string().required("Required"),
    payment_amount_2: yup.string().required("Required"),
    payment_final_payment: yup.string().required("Required"),
    payment_card_no: yup.string().required("Required"),
    payment_appr_code: yup.string().required("Required"),
  });