import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Button, TextField } from '@mui/material'
import { Formik } from "formik";
import { useMediaQuery } from "@mui/material";
import Header from "../../components/Header";
import {createBooking, getCustomerDetails, getGoogleLocationDetails} from "../../services/customerService";
import { toast } from 'react-toastify';
import { INITIAL_FORM_STATE, checkoutSchema } from "./utils";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Pieces from './pieces';
import Performa from './performa';
import './Booking.css';
import moment from 'moment-timezone';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Booking = () => {
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState({...INITIAL_FORM_STATE});
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [pieces, setPieces] = useState([]);
  const [noOfpieces, setNoOfPieces] = useState('');
  const [performa, setPerforma] = useState([]);
  const noneDisplay = {
    display: 'none',
  };
  const blockDisplay = {
    display: 'block',
    backgroundColor: 'rgba(0,0,0,3)',
  };
  const [showModalStyle, setShowModalStyle] = useState(noneDisplay);
  const [showPerformaStyle, setShowPerformaStyle] = useState(noneDisplay);
  useEffect(() => {
    (async () => {
      let response = await getCustomerDetails(id);
      let doc_type_full = '';
      if (response.result.doc_type === 1) {
        doc_type_full = 'PAN Card';
      } else if (response.result.doc_type === 2) {
        doc_type_full = 'Driving License';
      } else if (response.result.doc_type === 3) {
        doc_type_full = 'Voter ID';
      } else if (response.result.doc_type === 4) {
        doc_type_full = 'Aadhar Card';
      } else if (response.result.doc_type === 5) {
        doc_type_full = 'Passport';
      } else if (response.result.doc_type === 6) {
        doc_type_full = 'GST';
      } else if (response.result.doc_type === 7) {
        doc_type_full = 'KYC not collected';
      }
      setInitialValues({
        ...initialValues,
        company_name: response.result.type_name,
        contact_person: response.result.name,
        address1: response.result.address1,
        address2: response.result.address2,
        address2: response.result.address2,
        country: response.result.country,
        state: response.result.state,
        city: response.result.city,
        mobile: response.result.mobile,
        pincode: response.result.pincode,
        email: response.result.email,
        telephone: response.result.telephone,
        ship_doc_no: response.result.doc_number,
        ship_doc_type: doc_type_full,
        ship_expiry_date: moment(response.result.doc_expiry_date).tz('UTC').format('YYYY-MM-DD'),
        ship_ship_date: moment().tz('UTC').format('YYYY-MM-DD'),
      });
    })();
  }, []);
  useEffect(() => {
    let ttl_vol = 0;
    let ttl_grs = 0;
    pieces.forEach((element, i) => {
      let ttl = parseFloat(element.piece_length) * parseFloat(element.piece_height) * parseFloat(element.piece_width);
      pieces[i].piece_vol_weight = ttl/5000;
      ttl_vol = ttl_vol + ttl;
      ttl_grs = ttl_grs + element.piece_gross_weight;
    });
    setInitialValues({
      ...initialValues,
      ship_gross_weight: ttl_grs.toString(),
      ship_volumentric_weight: ttl_vol.toString(),
      ship_pieces: noOfpieces,
    });
  }, [pieces]);
  const onSelectCustomerHandler = async (setFieldValue, pin) => {
    setFieldValue('con_city', '');
    setFieldValue('con_state', '');
    setFieldValue('con_country', '');
    if(pin.length >= 5) {
      let response = await getGoogleLocationDetails(pin);
      if(response) {
        setFieldValue('con_city', response.administrative_area_level_3 ? response.administrative_area_level_3 : response.locality || response.administrative_area_level_2);
        setFieldValue('con_state', response.administrative_area_level_1);
        setFieldValue('con_country', response.country);
      }
    }
  };
  const calculateVolMet = (setFieldValue, values, val, type) => {
    let volumeCal = 1;
    if(type === 'ship_height') {
      volumeCal = parseFloat(val) * parseFloat(values.ship_length) * parseFloat(values.ship_width);
    } else if (type === 'ship_length') {
      volumeCal = parseFloat(val) * parseFloat(values.ship_height) * parseFloat(values.ship_width);
    } else if (type === 'ship_width') {
      volumeCal = parseFloat(val) * parseFloat(values.ship_length) * parseFloat(values.ship_height);
    }
    setFieldValue('ship_volumentric_weight', volumeCal/5000);
  };
  const handleFormSubmit = async (values) => {
    values.ship_invoice_date = values.ship_invoice_date.toISOString().slice(0, 10);
    values.pieces = pieces;
    values.performa = performa;
    let response = await createBooking(values);
    if (response.status === 'success') {
      toast.success('Booking Added Successfully!');
    }
    if (response.status === 'error') {
      toast.error(response.error);
    }
  };
  const openPiecesDetails = () => {
    setShowModalStyle(blockDisplay);
  };
  const closePiecesDetail = () => {
    setShowModalStyle(noneDisplay);
  };
  const openPerformaInvoice = () => {
    setShowPerformaStyle(blockDisplay);
  };
  const closePerformaInvoice = () => {
    setShowPerformaStyle(noneDisplay);
  };
  return (
    <Box m="20px">
      <Header title="International Booking" subtitle="Create a New International Booking" />
      <div className="modal modal-xl" tabindex="-1" role="dialog" style={showModalStyle}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <Header title="Pieces Details" subtitle="" />
              </h5>
            </div>
            <div className="modal-body">
              <Pieces pieces={pieces} setPieces={setPieces} noOfPcs={noOfpieces} />
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closePiecesDetail}>Okay</button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal modal-xl" tabindex="-1" role="dialog" style={showPerformaStyle}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <Header title="Performa Invoice" subtitle="" />
              </h5>
            </div>
            <div className="modal-body">
              <Performa performa={performa} setPerforma={setPerforma} noOfPcs={noOfpieces}/>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={openPerformaInvoice}>Update</button>
              <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closePerformaInvoice}>Okay</button>
            </div>
          </div>
        </div>
      </div>
      <Formik onSubmit={handleFormSubmit} initialValues={initialValues} validationSchema={checkoutSchema} enableReinitialize={true}>
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue}) => (
          <form onSubmit={handleSubmit}>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="shipper-tab" data-bs-toggle="tab" data-bs-target="#shipper" type="button" role="tab" aria-controls="shipper" aria-selected="true">Shipper</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="consignee-tab" data-bs-toggle="tab" data-bs-target="#consignee" type="button" role="tab" aria-controls="consignee" aria-selected="false">Consignee</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="shipment-tab" data-bs-toggle="tab" data-bs-target="#shipment" type="button" role="tab" aria-controls="shipment" aria-selected="false">Shipment Info</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="charges-tab" data-bs-toggle="tab" data-bs-target="#charges" type="button" role="tab" aria-controls="charges" aria-selected="false">Charges</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button" role="tab" aria-controls="payment" aria-selected="false">Payment</button>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="shipper" role="tabpanel" aria-labelledby="shipper-tab">
                <Box m="20px">
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Company Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company_name}
                      name="company_name"
                      error={!!touched.company_name && !!errors.company_name}
                      helperText={touched.company_name && errors.company_name}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Contact person"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.contact_person}
                      name="contact_person"
                      error={!!touched.contact_person && !!errors.contact_person}
                      helperText={touched.contact_person && errors.contact_person}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Address 1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address1}
                      name="address1"
                      error={!!touched.address1 && !!errors.address1}
                      helperText={touched.address1 && errors.address1}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Address 2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.address2}
                      name="address2"
                      error={!!touched.address2 && !!errors.address2}
                      helperText={touched.address2 && errors.address2}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.country}
                      name="country"
                      error={!!touched.country && !!errors.country}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="State"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.state}
                      name="state"
                      error={!!touched.state && !!errors.state}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="City"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.city}
                      name="city"
                      error={!!touched.city && !!errors.city}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Mobile Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.mobile}
                      name="mobile"
                      error={!!touched.mobile && !!errors.mobile}
                      helperText={touched.mobile && errors.mobile}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Pincode"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.pincode}
                      name="pincode"
                      error={!!touched.pincode && !!errors.pincode}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      name="email"
                      error={!!touched.email && !!errors.email}
                      helperText={touched.email && errors.email}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Telephone Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.telephone}
                      name="telephone"
                      error={!!touched.telephone && !!errors.telephone}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="GSTIN"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.gstin}
                      name="gstin"
                      error={!!touched.gstin && !!errors.gstin}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Company Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company_name2}
                      name="company_name2"
                      error={!!touched.company_name2 && !!errors.company_name2}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Status"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.status}
                      name="status"
                      error={!!touched.status && !!errors.status}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Box>
                </Box>
              </div>
              <div class="tab-pane fade" id="consignee" role="tabpanel" aria-labelledby="consignee-tab">
                <Box m="20px">
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Company Name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_company_name}
                      name="con_company_name"
                      error={!!touched.con_company_name && !!errors.con_company_name}
                      helperText={touched.con_company_name && errors.con_company_name}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Contact person/Dept"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_contact_person}
                      name="con_contact_person"
                      error={!!touched.con_contact_person && !!errors.con_contact_person}
                      helperText={touched.con_contact_person && errors.con_contact_person}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Address 1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_address1}
                      name="con_address1"
                      error={!!touched.con_address1 && !!errors.con_address1}
                      helperText={touched.con_address1 && errors.con_address1}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Address 2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_address2}
                      name="con_address2"
                      error={!!touched.con_address2 && !!errors.con_address2}
                      helperText={touched.con_address2 && errors.con_address2}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Country"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_country}
                      name="con_country"
                      error={!!touched.con_country && !!errors.con_country}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="City"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_city}
                      name="con_city"
                      error={!!touched.con_city && !!errors.con_city}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Mobile Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_mobile}
                      name="con_mobile"
                      error={!!touched.con_mobile && !!errors.con_mobile}
                      helperText={touched.con_mobile && errors.con_mobile}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Pincode"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("con_pincode", e.target.value);
                        onSelectCustomerHandler(setFieldValue, e.target.value);
                      }}
                      value={values.con_pincode}
                      name="con_pincode"
                      error={!!touched.con_pincode && !!errors.con_pincode}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_email}
                      name="con_email"
                      error={!!touched.con_email && !!errors.con_email}
                      helperText={touched.con_email && errors.con_email}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Telephone Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.con_telephone}
                      name="con_telephone"
                      error={!!touched.con_telephone && !!errors.con_telephone}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Box>
                </Box>
              </div>
              <div class="tab-pane fade" id="shipment" role="tabpanel" aria-labelledby="shipment-tab">
                <Box m="20px">
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Shipment Date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_ship_date}
                      name="ship_ship_date"
                      error={!!touched.ship_ship_date && !!errors.ship_ship_date}
                      helperText={touched.ship_ship_date && errors.ship_ship_date}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.ship_product_type}
                        label="Product Type"
                        name="ship_product_type"
                        onChange={handleChange}
                      >
                        <MenuItem value="DOX">DOX</MenuItem>
                        <MenuItem value="WPX">WPX</MenuItem>
                      </Select>
                    </FormControl>
                    </Box>
                    <hr />
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Pieces"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("ship_pieces", e.target.value);
                        setNoOfPieces(e.target.value);
                      }}
                      value={values.ship_pieces}
                      name="ship_pieces"
                      error={!!touched.ship_pieces && !!errors.ship_pieces}
                      helperText={touched.ship_pieces && errors.ship_pieces}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <div className="align-content-end col-md-4 w-75">
                      <button type="button" className="btn btn-secondary btn-sm w-100" onClick={openPiecesDetails}>
                        Piece Details
                      </button>
                    </div>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Gross weight"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_gross_weight}
                      name="ship_gross_weight"
                      error={!!touched.ship_gross_weight && !!errors.ship_gross_weight}
                      helperText={touched.ship_gross_weight && errors.ship_gross_weight}
                      sx={{ gridColumn: "span 1" }}
                      disabled={parseInt(values.ship_pieces) > 1}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Length"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("ship_length", e.target.value);
                        calculateVolMet(setFieldValue, values, e.target.value, 'ship_length');
                      }}
                      value={values.ship_length}
                      name="ship_length"
                      error={!!touched.ship_length && !!errors.ship_length}
                      sx={{ gridColumn: "span 1" }}
                      disabled={parseInt(values.ship_pieces) > 1}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Width"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("ship_width", e.target.value);
                        calculateVolMet(setFieldValue,values, e.target.value, 'ship_width');
                      }}
                      value={values.ship_width}
                      name="ship_width"
                      error={!!touched.ship_width && !!errors.ship_width}
                      sx={{ gridColumn: "span 1" }}
                      disabled={parseInt(values.ship_pieces) > 1}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Height"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("ship_height", e.target.value);
                        calculateVolMet(setFieldValue, values, e.target.value, 'ship_height');
                      }}
                      value={values.ship_height}
                      name="ship_height"
                      error={!!touched.ship_height && !!errors.ship_height}
                      helperText={touched.ship_height && errors.ship_height}
                      sx={{ gridColumn: "span 1" }}
                      disabled={parseInt(values.ship_pieces) > 1}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Volumetric Weight"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_volumentric_weight}
                      name="ship_volumentric_weight"
                      error={!!touched.ship_volumentric_weight && !!errors.ship_volumentric_weight}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    </Box>
                    <hr />
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Check if shipment contains Sample or Gift item(s)</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.ship_sample_or_gift}
                        label="Check if shipment contains Sample or Gift item(s)"
                        name="ship_sample_or_gift"
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>Gift/Personal Uses</MenuItem>
                        <MenuItem value={2}>Samples</MenuItem>
                        <MenuItem value={3}>Personal Effects</MenuItem>
                        <MenuItem value={4}>Commercial</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Contents"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_contents}
                      name="ship_contents"
                      error={!!touched.ship_contents && !!errors.ship_contents}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Document Type"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_doc_type}
                      name="ship_doc_type"
                      error={!!touched.ship_doc_type && !!errors.ship_doc_type}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    {/* <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Harmonised Comm Code"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_har_comm_code}
                      name="ship_har_comm_code"
                      error={!!touched.ship_har_comm_code && !!errors.ship_har_comm_code}
                      sx={{ gridColumn: "span 1" }}
                    /> */}
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Document Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_doc_no}
                      name="ship_doc_no"
                      error={!!touched.ship_doc_no && !!errors.ship_doc_no}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Expiry Date"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_expiry_date}
                      name="ship_expiry_date"
                      error={!!touched.ship_expiry_date && !!errors.ship_expiry_date}
                      sx={{ gridColumn: "span 1" }}
                      disabled
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Insurance</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.ship_insurance}
                        label="Insurance"
                        name="ship_insurance"
                        onChange={handleChange}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Invoice Number"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_invoice_no}
                      name="ship_invoice_no"
                      error={!!touched.ship_invoice_no && !!errors.ship_invoice_no}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <FormControl fullWidth sx={{ gridColumn: "span 2" }}>
                        <label id="demo-simple-select-label">Invoice Date</label>
                        <DatePicker
                          fullWidth
                          selected={values.ship_invoice_date}
                          onChange={(date) => setFieldValue("ship_invoice_date", date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode= "select"
                        />
                      </FormControl>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Remarks/Shipper Reference"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.ship_remarks}
                      name="ship_remarks"
                      error={!!touched.ship_remarks && !!errors.ship_remarks}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Box>
                </Box>
              </div>
              <div class="tab-pane fade" id="charges" role="tabpanel" aria-labelledby="charges-tab">
                <Box m="20px">
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    {/* <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Already HIT SCL"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_is_hit_scl}
                      name="charge_is_hit_scl"
                      error={!!touched.charge_is_hit_scl && !!errors.charge_is_hit_scl}
                      helperText={touched.charge_is_hit_scl && errors.charge_is_hit_scl}
                      sx={{ gridColumn: "span 1" }}
                    /> */}
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Basic Charges"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_basic_charge}
                      name="charge_basic_charge"
                      error={!!touched.charge_basic_charge && !!errors.charge_basic_charge}
                      helperText={touched.charge_basic_charge && errors.charge_basic_charge}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Fuel Charge"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_fuel_charge}
                      name="charge_fuel_charge"
                      error={!!touched.charge_fuel_charge && !!errors.charge_fuel_charge}
                      helperText={touched.charge_fuel_charge && errors.charge_fuel_charge}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Insurance Premium"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_insurance_premium}
                      name="charge_insurance_premium"
                      error={!!touched.charge_insurance_premium && !!errors.charge_insurance_premium}
                      helperText={touched.charge_insurance_premium && errors.charge_insurance_premium}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="RAS"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_ras}
                      name="charge_ras"
                      error={!!touched.charge_ras && !!errors.charge_ras}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Elevated Risk"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_elevated_risk}
                      name="charge_elevated_risk"
                      error={!!touched.charge_elevated_risk && !!errors.charge_elevated_risk}
                      sx={{ gridColumn: "span 1" }}
                    />
                    </Box>
                    <hr />
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Net Amount 1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_net_amount}
                      name="charge_net_amount"
                      error={!!touched.charge_net_amount && !!errors.charge_net_amount}
                      helperText={touched.charge_net_amount && errors.charge_net_amount}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="GST Tax"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_gst_tax}
                      name="charge_gst_tax"
                      error={!!touched.charge_gst_tax && !!errors.charge_gst_tax}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">TDS</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.charge_tds}
                        label="TDS"
                        name="charge_tds"
                        onChange={handleChange}
                      >
                        <MenuItem value="0.5">0.5%</MenuItem>
                        <MenuItem value="1">1%</MenuItem>
                        <MenuItem value="1.5">1.5%</MenuItem>
                        <MenuItem value="2">2%</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Final Payment"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.charge_final_payment}
                      name="charge_final_payment"
                      error={!!touched.charge_final_payment && !!errors.charge_final_payment}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Box>
                </Box>
              </div>
              <div class="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">
                <Box m="20px">
                  <Box
                    display="grid"
                    gap="30px"
                    gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                    sx={{
                      "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                    }}
                  >
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Shipping Account Number (Cash Code)"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_shipping_acc_no}
                      name="payment_shipping_acc_no"
                      error={!!touched.payment_shipping_acc_no && !!errors.payment_shipping_acc_no}
                      helperText={touched.payment_shipping_acc_no && errors.payment_shipping_acc_no}
                      sx={{ gridColumn: "span 1" }}
                    />
                    </Box>
                    <hr />
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Payment Mode 1</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.payment_mode_1}
                        label="Payment Mode 1"
                        name="payment_mode_1"
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>Cash</MenuItem>
                        <MenuItem value={2}>Debit / Credit Card</MenuItem>
                        <MenuItem value={3}>EFT</MenuItem>
                        <MenuItem value={4}>Online Payment</MenuItem>
                        <MenuItem value={5}>Credit</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Net Amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_net_amt_1}
                      name="payment_net_amt_1"
                      error={!!touched.payment_net_amt_1 && !!errors.payment_net_amt_1}
                      helperText={touched.payment_net_amt_1 && errors.payment_net_amt_1}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="GST Tax 1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_gst_tax_1}
                      name="payment_gst_tax_1"
                      error={!!touched.payment_gst_tax_1 && !!errors.payment_gst_tax_1}
                      helperText={touched.payment_gst_tax_1 && errors.payment_gst_tax_1}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Payment Amount 1"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_amount_1}
                      name="payment_amount_1"
                      error={!!touched.payment_amount_1 && !!errors.payment_amount_1}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Final Payment"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_final_payment}
                      name="payment_final_payment"
                      error={!!touched.payment_final_payment && !!errors.payment_final_payment}
                      sx={{ gridColumn: "span 1" }}
                    />
                    </Box>
                    <hr />
                    <Box
                      display="grid"
                      gap="30px"
                      gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                      sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                      }}
                    >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Payment Mode 2</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.payment_mode_2}
                        label="Payment Mode 2"
                        name="payment_mode_2"
                        onChange={handleChange}
                      >
                        <MenuItem value={1}>Cash</MenuItem>
                        <MenuItem value={2}>Debit / Credit Card</MenuItem>
                        <MenuItem value={3}>EFT</MenuItem>
                        <MenuItem value={4}>Online Payment</MenuItem>
                        <MenuItem value={5}>Credit</MenuItem>
                      </Select>
                    </FormControl>
                    {(values.payment_mode_2 === 3 || values.payment_mode_2 === 4) && (
                      <>
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Bank"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.payment_bank}
                          name="payment_bank"
                          error={!!touched.payment_bank && !!errors.payment_bank}
                          helperText={touched.payment_bank && errors.payment_bank}
                          sx={{ gridColumn: "span 1" }}
                        />
                        <TextField
                          fullWidth
                          variant="filled"
                          type="text"
                          label="Bank Branch"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.payment_bank_branch}
                          name="payment_bank_branch"
                          error={!!touched.payment_bank_branch && !!errors.payment_bank_branch}
                          helperText={touched.payment_bank_branch && errors.payment_bank_branch}
                          sx={{ gridColumn: "span 1" }}
                        />
                      </>
                    )}
                    {values.payment_mode_2 === 3 && (
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="UTR No"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.payment_utr_no}
                        name="payment_utr_no"
                        error={!!touched.payment_utr_no && !!errors.payment_utr_no}
                        helperText={touched.payment_utr_no && errors.payment_utr_no}
                        sx={{ gridColumn: "span 1" }}
                      />
                    )}
                    {values.payment_mode_2 === 4 && (
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="UTR No"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.payment_transaction_no}
                        name="payment_transaction_no"
                        error={!!touched.payment_transaction_no && !!errors.payment_transaction_no}
                        helperText={touched.payment_transaction_no && errors.payment_transaction_no}
                        sx={{ gridColumn: "span 1" }}
                      />
                    )}
                    
                    {values.payment_mode_2 === 2 && (
                      <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Card No(Last 4 digits)"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.payment_card_no}
                        name="payment_card_no"
                        error={!!touched.payment_card_no && !!errors.payment_card_no}
                        helperText={touched.payment_card_no && errors.payment_card_no}
                        sx={{ gridColumn: "span 1" }}
                      />
                    )}
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Net Amount"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_net_amt_2}
                      name="payment_net_amt_2"
                      error={!!touched.payment_net_amt_2 && !!errors.payment_net_amt_2}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="GST Tax 2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_gst_tax_2}
                      name="payment_gst_tax_2"
                      error={!!touched.payment_gst_tax_2 && !!errors.payment_gst_tax_2}
                      helperText={touched.payment_gst_tax_2 && errors.payment_gst_tax_2}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="Payment Amount 2"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_amount_2}
                      name="payment_amount_2"
                      error={!!touched.payment_amount_2 && !!errors.payment_amount_2}
                      sx={{ gridColumn: "span 1" }}
                    />
                    <TextField
                      fullWidth
                      variant="filled"
                      type="text"
                      label="APPR CODE"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.payment_appr_code}
                      name="payment_appr_code"
                      error={!!touched.payment_appr_code && !!errors.payment_appr_code}
                      sx={{ gridColumn: "span 1" }}
                    />
                  </Box>
                </Box>
              </div>
            </div>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained" className="me-md-1">
                Book Shipment
              </Button>
              <Button type="button" color="secondary" variant="contained" onClick={openPerformaInvoice}>
                Performa Invoice
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default Booking;